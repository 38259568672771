@import "bootstrap/dist/css/bootstrap.min.css"
@tailwind base;
@tailwind components;
@tailwind utilities;
*{
  box-sizing: border-box;

}

.apy{
  background-color:black;
}

